<template>
  <v-app-bar :elevation="0" dense :color="dark ? '#272727' : 'white'"
    v-if="itemsPerPage !== null && count !== null && startIndex !== null"
    
  >
    <v-spacer />
    <span style="padding: 0 10px;">{{ info }}</span>
    <v-icon :disabled="disableStart" @click="handleChangePage(-itemsPerPage)">mdi-chevron-left</v-icon>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          x-small
          v-if="onChangeItemsPerPage"
        >
        {{ itemsPerPage }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item of [5, 10, 15, 20, 25, 50]"
          :key="item"
          @click="handleChangeItemsPerPage(item)"
        >
        <v-list-item-title><center>{{ item }}</center></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-icon :disabled="disableEnd" @click="handleChangePage(itemsPerPage)" style="margin-left: 0px">mdi-chevron-right</v-icon>
  </v-app-bar>
</template>

<script>
  export default {
    props: {
      startIndex: {
        type: Number,
        required: false,
        default: null
      },
      count: {
        type: Number,
        required: false,
        default: null
      },
      itemsPerPage: {
        type: Number,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: true,
        default: null
      },
      onChangeItemsPerPage: {
        type: Function,
        default: null,
      },
      locale: {
        type: String,
        required: true
      },
      dark: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        info: '',
        disableStart: true,
        disableEnd: true
      }
    },
    watch: {
      itemsPerPage() {
        this.setInfo()
      },
      startIndex () {
        this.setInfo()
      },
      count () {
        this.setInfo()
      }
    },
    mounted () {
      this.setInfo()
    },
    methods: {
      setInfo () {
        const end = this.startIndex + this.itemsPerPage
        const start = this.startIndex
        this.disableStart = start <= 0
        this.disableEnd = end >= this.count
        this.info = `${start}-${end > this.count ? this.count : end} ${this.$t('common.of', this.locale)} ${this.count}`
      },
      handleChangePage (v) {
        const aux = this.startIndex + v
        this.onGetData(aux < 0 ? 0 : aux)
      },
      handleChangeItemsPerPage (v) {
        this.onChangeItemsPerPage(v)
      },
    }
  }
</script>

<style scoped>
  span {
    font-size: 0.75rem;
  }
</style>

