<template>
  <v-row 
    v-if="config"
    no-gutters
  >
    <v-col
      cols="8"
      style="padding-right: 10px;"
    >
    <v-row 
      v-if="config"
      no-gutters
    >
      <v-col
        cols="4"
        style="padding: 10px;"
      >
        <v-text-field
          outlined
          append-icon="mdi-magnify"
          :prepend-inner-icon="search !== '' ? 'mdi-close' : ''"
          hide-details
          dense
          style="margin-right:10px;"
          v-model="search"
          autofocus
          @click:append="handleGetData"
          @click:prepend-inner="handleClear"
          v-on:keyup.enter="handleGetData"
        />
      </v-col>
      <v-col
        cols="4"
        style="padding: 10px 10px 0 0;"
      >
        <v-btn
          color="primary"
          @click="handleGetData"
          block
        >
          {{$t('common.refresh', locale)}}
        </v-btn>
      </v-col>
      <v-col
        cols="4"
        style="padding-top:10px;"
      >
        <v-btn-toggle
          v-model="last24h"
          borderless
          color="primary"
          dense
          @change="handleChangeLast24h"
        >
          <v-btn :value="true">
            {{$t('dtouch.request.last24h', locale)}}
          </v-btn>

          <v-btn :value="false">
            All
            {{$t('dtouch.request.all', locale)}}
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col
        cols="12"
        style="padding: 0 10px 10px 10px;"
      >      
        <v-autocomplete
          v-if="sStatus"
          v-model="filterStatus"
          :items="sStatus"
          :label="`${$t('dtouch.request.status', locale)}`"
          item-text="sName"
          item-value="id"
          multiple
          chips
          clearable
          deletable-chips
          small-chips
          outlined
          dense
          hide-details
          @change="handleChangeFilterStatus"
        />
      </v-col>
      <v-col
        cols="12"
      >
    <div style="background-color: white;">
      <v-data-table
        v-if="items"
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:[`item.ident`]="{ item }">
          <p style="margin-bottom:0;">
            <a @click="handleEdit(item.ID)">{{ item.FullName }}</a>
          </p>
          <p style="margin-bottom:0;">
            <a @click="handleEdit(item.ID)">{{ item.Email }}</a>
          </p>
          <p style="margin-bottom:0;">
            <a @click="handleEdit(item.ID)">{{ item.Room }}</a>
          </p>
        </template>
        <template v-slot:[`item.Name`]="{ item }">
            {{ getTranslation(item.Name, locale) }}
        </template>
        <template v-slot:[`item.Image`]="{ item }">
          <v-img
            v-if="item.Image"
            :src="item.Image"
            style="width:90px;height: auto;"
          />
        </template>
        <template v-slot:[`item.Status`]="{ item }">
          <status-control
            :key="item.ID"
            :status="item.Status"
            :value="item"
            :onChange="handleChangeStatus"
            :locale="locale"
          />
        </template>
        <template v-slot:[`item.logs`]="{ item }">
          <p v-if="item.CD" style="margin-bottom:0"><b>INI: </b>{{item.INI}}</p>
          <p v-if="item.ED" style="margin-bottom:0"><b>END: </b>{{item.END}}</p>
        </template>
      </v-data-table>
      <pagination
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :onGetData="handleGetData"
        :locale="locale"
        :count="count"
        :onChangeItemsPerPage="handleChangeItemsPerPage"
      />
    </div>
      </v-col>
    </v-row>
    </v-col>
    <v-col
      cols="4"
    >
      <request-form 
        v-if="type"
        :editedID="selectedID"
        :workspaceID="workspaceID"
        :locale="locale"
        :languages="config.languages"
        :onClose="handleCloseForm"
        :type="type"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import apiDtouch from '@/services/apiDtouch'
import RequestForm from './Form'
import StatusControl from './StatusControl'
import Pagination from './Pagination'
export default {
  components: {
    RequestForm,
    StatusControl,
    Pagination,
  },
  data: () => ({
    type: null,
    config: null,
    selectedID: null,
    items: null,
    search: '',
    startIndex: 0,
    itemsPerPage: 10,
    count: 0,
    last24h: true,
    sStatus: null,
    filterStatus: ['new', 'pending', 'inProgress'],
  }),
  computed: {        
    ...mapState('app', ['locale']),
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    headers () {
      return [
          {
            text: this.$t('dtouch.request.customer', this.locale),
            align: 'start',
            value: 'ident',
          },
          {
            text: this.$t('dtouch.request.item', this.locale),
            align: 'start',
            value: 'Name',
          },
          {
            text: '',
            align: 'start',
            value: 'Image',
          },
        /*
          {
            text: this.$t('dtouch.request.email', this.locale),
            align: 'start',
            value: 'Email',
          },
          {
            text: this.$t('dtouch.request.room', this.locale),
            align: 'start',
            value: 'Room',
          },*/
          {
            text: this.$t('dtouch.request.comment', this.locale),
            align: 'start',
            value: 'Comment',
          },
          {
            text: this.$t('dtouch.request.status', this.locale),
            align: 'center',
            value: 'Status',
          },
          {
            text: '', //this.$t('dtouch.request.cd', this.locale),
            align: 'left',
            value: 'logs',
          },
        ]
    },
    status () {
      return [
        {
          id: 'new',
          name: { es: 'Nueva', en: 'New' },
          close: false,
          color: 'blue',
          dark: true,
        },
        {
          id: 'pending',
          name: { es: 'Pendiente', en: 'Pending' },
          close: false,
          color: 'pink',
          dark: true,
        },
        {
          id: 'inProgress',
          name: { es: 'En progreso', en: 'In progress' },
          close: false,
          color: 'yellow',
          dark: false,
        },
        {
          id: 'solved',
          name: { es: 'Resuelta', en: 'Solved' },
          close: true,
          color: 'green',
          dark: true,
        },
        {
          id: 'rejected',
          name: { es: 'Rechazada', en: 'Rejected' },
          close: true,
          color: 'red',
          dark: true,
        },
      ]
    },
  },
  watch: {
    '$route.meta.type' () {
      this.init()
    },
  },
  mounted () {
    this.sStatus = this.status.map(item => {
      item.sName = this.getTranslation(item.name, this.locale)
      return item
    })
    this.init()
  },
  methods: {
    init () {
      this.search = ''
      this.type = this.$route.meta.type
      this.handleGetConfig()
      this.handleGetData()
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetConfig () {
      apiDtouch.getItem (`v2/private/workspaces/${this.workspaceID}`)
        .then(response => {
          if (!response) return
          this.config = {
            languages: response.Languages ? response.Languages.split(',') : []
          }
        })
    },
    handleClear () {
      this.search = ''
      this.handleGetData()
    },
    handleGetData (v) {
      if (v >= 0) this.startIndex = v
      this.requests = null
      apiDtouch.getAll (`v2/private/workspaces/${this.workspaceID}/${this.type}`, this.startIndex, this.itemsPerPage, this.search, this.last24h, this.filterStatus)
        .then(response => {
          if (!response) return
          this.count = response.count
          this.items = response.data.map(item => {
            return item
          })
        })
    },
    handleEdit (v) {
      this.selectedID = v
    },
    handleChangeStatus (v) {
      apiDtouch.updateItem(`v2/private/${this.type}/${v.ID}`, v)
        .then(response => {
          if (!response) return
          if (response.data.status === 'OK') {
            this.handleGetData()
          } else alert(this.$t(`dtouch.request.messages.${response.data.message}`, this.locale))
        })
        .catch(() => {
          alert ('ERROR')
        })
    },
    handleChangeLast24h () {
      this.handleGetData(0)
    },
    handleChangeFilterStatus () {
      this.handleGetData(0)
    },
    handleCloseForm (v) {
      this.selectedID = null
      if (v) this.handleGetData()
    },
    handleChangeItemsPerPage (v) {
      this.itemsPerPage = v
      this.handleGetData(0)
    },
  },
}
</script>

